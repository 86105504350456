import Vue from "vue";
import axios from "axios";
import store from "./store";

const emnifyApi = axios.create({
	baseURL: "https://cdn.emnify.net/api/v1",
	timeout: 8000,
	withCredentials: false,
});

const ioteeDashboardApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_DASHBOARD_API,
	timeout: 15000,
	withCredentials: false,
});

const ioteeAdminApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_ADMIN_API,
	timeout: 15000,
	withCredentials: false,
});

const ioteeGuestApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_GUEST_API,
	timeout: 15000,
	withCredentials: false,
	headers: {
		common: {
			"x-api-key": process.env.VUE_APP_MAIN_KEY,
		},
	},
});

const ioteeStorageApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_STORAGE_API,
	timeout: 15000,
	withCredentials: false,
	headers: {
		common: {
			"main-key": process.env.VUE_APP_MAIN_KEY,
		},
	},
});

Vue.prototype.$emnifyApi = emnifyApi;
Vue.prototype.$ioteeDashboardApi = ioteeDashboardApi;
Vue.prototype.$ioteeAdminApi = ioteeAdminApi;
Vue.prototype.$ioteeGuestApi = ioteeGuestApi;
Vue.prototype.$ioteeStorageApi = ioteeStorageApi;

// List of endpoints that should not trigger a token refresh
const skipRefreshEndpoints = ["/auth/refresh", "/auth/logout"];

// Centralized token refresh function to avoid duplication
const handleTokenRefresh = async (originalRequest, apiInstance) => {
	try {
		// Get new token
		const accessToken = await store.dispatch("refreshToken");

		// Clone and update the original request
		const updatedRequest = {
			...originalRequest,
			headers: {
				...originalRequest.headers,
				Authorization: `Bearer ${accessToken}`,
			},
			_retry: true,
		};

		// Retry with new token
		return apiInstance(updatedRequest);
	} catch (error) {
		// Clear tokens on failure
		localStorage.removeItem("user");
		localStorage.removeItem("refreshToken");

		// Router will handle redirect in next navigation attempt
		return Promise.reject(error);
	}
};

// Create response interceptor factory
const createAuthInterceptor = (apiInstance) => {
	return async (error) => {
		const originalRequest = error.config;
		const isSkipEndpoint = skipRefreshEndpoints.some((endpoint) => originalRequest.url.includes(endpoint));

		// Only attempt refresh for 401 errors on non-auth endpoints
		// and only attempt once per request
		if (error.response?.status === 401 && !originalRequest._retry && !isSkipEndpoint) {
			return handleTokenRefresh(originalRequest, apiInstance);
		}

		return Promise.reject(error);
	};
};

// Apply the interceptor to both API instances
ioteeDashboardApi.interceptors.response.use((response) => response, createAuthInterceptor(ioteeDashboardApi));

ioteeAdminApi.interceptors.response.use((response) => response, createAuthInterceptor(ioteeAdminApi));

export const setApiHeaders = ({ token, siteId }) => {
	if (token) {
		ioteeDashboardApi.defaults.headers.common["Authorization"] = "Bearer " + token;
		ioteeAdminApi.defaults.headers.common["Authorization"] = "Bearer " + token;
	}

	if (siteId) {
		ioteeDashboardApi.defaults.headers.common["site-id"] = siteId;
	}
};

export default axios;
