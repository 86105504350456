<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on">
				<v-list-item-title>
					<v-icon small left>mdi-chip</v-icon>
					Hardware Info
				</v-list-item-title>
			</v-list-item>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>Hardware Info</span>
			</v-card-text>
			<v-card-text>
				<v-form ref="form" v-model="form" lazy-validation>
					<v-select :items="pcbTypes" v-model="hardwareInfo.pcb" label="PCB Type" hide-details="auto" item-value="rev" return-object outlined dense :rules="rules" class="mb-3">
						<template slot="selection" slot-scope="data">
							{{ data.item.rev }}
							<v-chip x-small class="padlessBtn font-weight-bold ml-2" label>
								{{ data.item.connector }}
							</v-chip>
						</template>
						<template slot="item" slot-scope="data">
							{{ data.item.rev }}
							<v-chip x-small class="padlessBtn font-weight-bold ml-2" label>
								{{ data.item.connector }}
							</v-chip>
						</template>
					</v-select>
					<v-select :items="modemTypes" v-model="hardwareInfo.modem" label="Modem Type" hide-details="auto" outlined dense class="mb-3" :rules="rules"> </v-select>
					<v-checkbox v-model="hardwareInfo.externalAntenna" persistent-hint color="accent">
						<template v-slot:label>
							<div>
								<span class="font-weight-bold accent--text"> Externally Mounted Antenna </span>
							</div>
						</template>
					</v-checkbox>
					<v-text-field label="Notes" hide-details="auto" v-model="hardwareInfo.notes" outlined dense clearable class="mb-3"> </v-text-field>
					<v-spacer></v-spacer>
					<v-btn @click="submit()" :loading="loading" block class="primary">
						{{ $t("general.save") }}
					</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			loading: false,
			form: true,
			hardwareInfo: {
				pcb: null,
				modem: null,
				externalAntenna: false,
				notes: null,
				connector: null,
			},
			pcbTypes: [
				{ header: "V1" },
				{ rev: "v1.3.3", connector: "10 pin push" },
				{ rev: "v1.3.4", connector: "4 pin molex" },
				{ header: "V2" },
				{ rev: "v2.0", connector: "10 pin push" },
				{ rev: "v2.0 m", connector: "4 pin molex" },
				{ rev: "v2.2", connector: "6 pin molex" },
				{ rev: "v2.3", connector: "6 pin molex" },
				{ rev: "v2.4", connector: "6 pin molex" },
				{ rev: "v2.5", connector: "6 pin molex" },
				{ header: "V3" },
				{ rev: "v3.0", connector: "6 pin molex" },
				{ rev: "v3.1", connector: "6 pin molex" },
				{ rev: "v3.2", connector: "6 pin molex" },
				{ rev: "v3.3", connector: "6 pin molex" },
				{ header: "V4" },
				{ rev: "v4.0", connector: "6 pin molex" },
				{ rev: "v4.1", connector: "6 pin molex" },
				{ rev: "v4.2", connector: "6 pin molex" },
				{ header: "V6" },
				{ rev: "v6.0", connector: "6 pin molex" },
				{ rev: "v6.1", connector: "6 pin molex" },
				{ rev: "v6.2", connector: "6 pin molex" },
				{ header: "Other" },
				{ rev: "ESP-Test", connector: "usb" },
				{ rev: "Other", connector: "other" },
			],
			modemTypes: [{ header: "SIMCOM" }, "SIM7600", "SIM7000", { header: "Other" }, "Other"],
			rules: [(v) => !!v || this.$i18n.t("rules.required")],
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				this.$emit("hardwareInfo", this.hardwareInfo);
				this.dialog = false;
			}
		},

		reset() {
			this.form = null;
			this.$refs.form.resetValidation();
		},
	},
};
</script>
