<template>
	<l-map :zoom="zoom" class="rideMap" ref="map" :options="{ zoomControl: false }">
		<l-tile-layer :url="url" :options="{ maxZoom: 19 }" />

		<l-marker v-if="startMarker" :lat-lng="startMarker">
			<l-icon>
				<div class="pill">
					<v-icon small color="primary">mdi-numeric-1-circle</v-icon>
				</div>
			</l-icon>
		</l-marker>
		<l-marker v-if="endMarker" :lat-lng="endMarker">
			<l-icon>
				<div class="pill">
					<v-icon small color="primary">mdi-numeric-2-circle</v-icon>
				</div>
			</l-icon>
		</l-marker>

		<!-- Recenter Button -->
		<l-control style="margin-top: 12px" position="topright">
			<v-btn small @click="recenterMap()" class="mapBtn" color="accent">
				<v-icon color="primary" small>mdi-navigation</v-icon>
			</v-btn>
		</l-control>
		<!-- Toggle Map Layer Button -->
		<l-control position="topright" style="margin-left: 8px; margin-top: 8px">
			<v-btn small @click="tile = !tile" :color="tile ? 'accent' : 'white'" class="primary--text padlessBtn" :class="tile ? 'primary--text' : 'accent--text'">
				<v-icon small>mdi-map</v-icon>
			</v-btn>
		</l-control>
	</l-map>
</template>

<script>
// eslint-disable-next-line
import L from "leaflet";
//import snakeit
// import "leaflet.polyline.snakeanim";
import "@geoman-io/leaflet-geoman-free";
import { LMap, LTileLayer, LControl, LMarker, LIcon } from "vue2-leaflet";
import { latLng } from "leaflet";
import { antPath } from "leaflet-ant-path";

import polyline from "google-polyline";

export default {
	name: "Map",
	components: {
		LMap,
		LTileLayer,
		LControl,
		LMarker,
		LIcon,
	},
	props: {
		selectedSite: {
			default: null,
		},
		selectedRide: {
			default: null,
		},
		hiResTrail: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			carsList: true,
			polyline: null,
			dialog: false,
			zoom: 17,
			tile: true,
			toolbar: 0,
			map: null,
			mapObject: null,
			trailGroup: null,
			startMarker: null,
			endMarker: null,
		};
	},
	computed: {
		url() {
			if (this.tile === false) {
				return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
			} else {
				return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
			}
		},

		points() {
			if (this.hiResTrail && this.selectedRide.polyline) {
				return polyline.decode(this.selectedRide.polyline);
			}

			return this.selectedRide.history && this.selectedRide.history.coordinates ? this.selectedRide.history.coordinates : [];
		},
	},
	watch: {
		selectedRide() {
			this.refreshTrails();
		},

		hiResTrail() {
			this.refreshTrails();
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.mapObject = this.$refs.map.mapObject;

			this.refreshTrails();
		});
	},
	methods: {
		refreshTrails() {
			if (this.points.length > 2) {
				if (this.trailGroup) this.hideTrails();

				this.showTrails();
			} else {
				this.startMarker = null;
				this.endMarker = null;

				this.hideTrails();
			}
		},

		showTrails() {
			this.trailGroup = L.layerGroup();

			const options = {
				color: "#7998fb",
				pulseColor: "#406dfa",
				delay: 4000,
				opacity: 1,
				pmIgnore: true,
			};

			this.trailGroup.addLayer(antPath(this.points, options));
			this.trailGroup.addTo(this.mapObject);

			this.recenterMap();

			if (this.points.length > 2) {
				const [lat, long] = this.points[0];
				this.startMarker = latLng(lat, long);

				const [endLat, endLong] = this.points[this.points.length - 1];
				this.endMarker = latLng(endLat, endLong);
			}
		},

		hideTrails() {
			if (this.trailGroup) {
				this.trailGroup.removeFrom(this.mapObject);
			}
		},

		centerLatLong() {
			// Check if there are points for the selected ride and use the first point as the center
			if (this.selectedRide && this.points && this.points.length > 0) {
				const [lat, long] = this.points[0];
				return latLng(lat, long);
			} else if (this.selectedSite && this.selectedSite.centerLatLong) {
				// Fallback to the selectedSite center lat long
				const [lat, long] = this.selectedSite.centerLatLong.split(",");
				return latLng(lat, long);
			} else {
				// Default coordinates in case no data is available
				return latLng(0, 0);
			}
		},

		recenterMap() {
			this.$refs.map.mapObject.invalidateSize();

			// Check if there's a polyline and use fitBounds if so
			if (this.points && this.points.length > 0) {
				console.log("fitBounds");
				const polyline = L.polyline(this.points);
				this.$refs.map.mapObject.fitBounds(polyline.getBounds());
			} else {
				// Fallback to centerLatLong
				this.$refs.map.mapObject.setView(this.centerLatLong(), this.zoom);
			}
		},
	},
};
</script>

<style>
.rideMap {
	width: 100%;
	height: 100%;
	z-index: 1;
	/* bottom: 0; */
	/* height: calc(100vh - var(--vh-offset, 60px)); */
	/* height: 100vh -120px !important; */
}

.mapBtn {
	padding-left: 9px !important;
	padding-right: 9px !important;
	min-width: 0 !important;
}

.pill {
	display: inline-flex;
	align-items: center;
	padding: 1px;
	border-radius: 25px;
	background-color: white;
}
</style>
