import Vue from "vue";

export const login = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/login", payload);

		return {
			accessToken: data.accessToken,
			refreshToken: data.refreshToken,
		};
	} catch (err) {
		throw err.response.data;
	}
};

export const getUser = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/auth");

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateUser = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch("/auth", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const registerUser = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/register", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const forgotPassword = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/forgot-password", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const resetPassword = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/reset-password", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const refreshToken = async (refreshToken) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/refresh", { refreshToken });

		return data.accessToken;
	} catch (err) {
		throw err.response.data;
	}
};

export const logout = async () => {
	try {
		await Vue.prototype.$ioteeDashboardApi.post("/auth/logout");
		return true;
	} catch (err) {
		throw err.response.data;
	}
};
