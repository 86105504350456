import Vue from "vue";
import VueRouter from "vue-router";
import { hasRole } from "../mixins/auth";
import store from "../store";

// Map
import MapView from "../views/map/Map.vue";

// Fleet
import Fleet from "../views/fleet/Fleet.vue";

// Virtual Keys
import VirtualKeys from "../views/virtualKeys/index.vue";

// Rides
import Rides from "../views/rides/Rides.vue";

// Charts
import Charts from "../views/charts/Charts.vue";
import Revenue from "../views/charts/Payments.vue";
import Usage from "../views/charts/Usage.vue";

// Bookings
import Bookings from "../views/bookings/Bookings.vue";

// Site Settings
import Settings from "../views/settings/index.vue";
import GeneralSettings from "../views/settings/GeneralSettings.vue";
import BookingSettings from "../views/settings/BookingSettings.vue";
import AccountSettings from "../views/settings/AccountSettings.vue";

// Admin
import Admin from "../views/admin/Index.vue";
import UserManager from "../views/admin/UserManager.vue";
import InvitationsManager from "../views/admin/InvitationsManager.vue";
import SiteManager from "../views/admin/SiteManager.vue";
import DeviceManager from "../views/admin/DeviceManager.vue";
import AdminDashboard from "../views/admin/Dashboard.vue";
import ClientManager from "../views/admin/ClientManager.vue";
import ActivityViewer from "../views/admin/ActivityViewer.vue";

// Login
import Login from "../views/auth/Login.vue";
import LoginForm from "../components/auth/Login.vue";
import ResetPassword from "../components/auth/ResetPassword.vue";
import ResetPasswordForm from "../components/auth/ResetPasswordForm.vue";
import Register from "../components/auth/Register.vue";

//Unlock Cart
import Scan from "../views/unlock/Scan.vue";

//Ride
import Ride from "../views/ride/Ride.vue";
import RideHome from "../views/ride/RideHome.vue";
import RideBooking from "../views/ride/RideBooking.vue";
import RideVirtualKey from "../views/ride/RideVirtualKey.vue";
import RidePrices from "../views/ride/RidePrices.vue";
import RideSession from "../views/ride/RideSession.vue";

// Guest
import GuestIndex from "../views/guest/index.vue";
import GuestSite from "../views/guest/Site.vue";
import GuestVirtualKey from "../views/guest/VirtualKey.vue";
import GuestMasterKey from "../views/guest/MasterKey.vue";
import GuestBooking from "../views/guest/Booking.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Map",
		component: MapView,
		meta: {
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
	},
	{
		path: "/login",
		component: Login,
		meta: {
			loginPages: true,
			layout: "AppLayoutGuest",
		},
		children: [
			{
				path: "",
				component: LoginForm,
				meta: {
					loginPages: true,
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "/login/register",
				component: Register,
				meta: {
					loginPages: true,
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "/login/resetPassword",
				component: ResetPassword,
				meta: {
					loginPages: true,
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "/login/passwordReset",
				component: ResetPasswordForm,
				meta: {
					loginPages: true,
					layout: "AppLayoutGuest",
				},
			},
		],
	},
	{
		path: "/fleet",
		component: Fleet,
		meta: {
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
	},
	{
		path: "/virtualKeys",
		component: VirtualKeys,
		meta: {
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
	},
	{
		path: "/rides",
		component: Rides,
		name: "Rides",
		meta: {
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
	},
	{
		path: "/charts",
		redirect: "/charts/revenue",
		component: Charts,
		props: true,
		meta: {
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
		children: [
			{
				path: "/charts/revenue", //selected tab by default
				component: Revenue,
				meta: {
					title: "Revenue",
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/charts/usage", //selected tab by default
				component: Usage,
				meta: {
					title: "Usage",
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
		],
	},
	{
		path: "/settings",
		redirect: "/settings/general",
		component: Settings,
		props: true,
		meta: {
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
		children: [
			{
				path: "/settings/general", //selected tab by default
				component: GeneralSettings,
				meta: {
					title: "General",
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/settings/booking",
				component: BookingSettings,
				meta: {
					title: "Booking Settings",
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/settings/account",
				component: AccountSettings,
				meta: {
					title: "Account Settings",
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
		],
	},
	{
		path: "/bookings",
		component: Bookings,
		meta: {
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
	},
	{
		path: "/admin",
		redirect: "/admin/devices",
		component: Admin,
		props: true,
		meta: {
			adminAuth: true,
			requiresAuth: true,
			layout: "AppLayoutAuth",
		},
		children: [
			{
				path: "/admin/devices",
				component: DeviceManager,
				meta: {
					adminAuth: true,
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/admin/users", //selected tab by default
				component: UserManager,
				meta: {
					adminAuth: true,
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/admin/invites", //selected tab by default
				component: InvitationsManager,
				meta: {
					adminAuth: true,
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/admin/sites",
				component: SiteManager,
				meta: {
					adminAuth: true,
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/admin/dashboard",
				component: AdminDashboard,
				meta: {
					adminAuth: true,
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/admin/clients",
				component: ClientManager,
				meta: {
					adminAuth: true,
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
			{
				path: "/admin/activity",
				component: ActivityViewer,
				meta: {
					adminAuth: true,
					requiresAuth: true,
					layout: "AppLayoutAuth",
				},
			},
		],
	},
	{
		path: "/unlock/:id",
		component: Scan,
		props: true,
		meta: {
			layout: "AppLayoutGuest",
		},
	},
	{
		path: "/ride",
		component: Ride,
		props: true,
		meta: {
			layout: "AppLayoutGuest",
		},
		children: [
			{
				path: "/ride/:id",
				name: "RideHome",
				component: RideHome,
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "/ride/:id/prices",
				name: "RidePrices",
				component: RidePrices,
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "/ride/:id/booking/:bookingId",
				name: "RideBooking",
				component: RideBooking,
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "/ride/:id/virtual-key/:code",
				name: "RideVirtualKey",
				component: RideVirtualKey,
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "/ride/:id/session/:sessionId",
				name: "RideSession",
				component: RideSession,
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
		],
	},
	{
		path: "/guest",
		component: GuestIndex,
		children: [
			{
				path: "site/:id",
				component: GuestSite,
				name: "guest.site",
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "site/:id/virtual-key/:virtualKeyId",
				component: GuestVirtualKey,
				name: "guest.virtualKey",
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
			{
				path: "site/:id/master-key/:virtualKeyId",
				component: GuestMasterKey,
				name: "guest.masterKey",
				props: true,
				meta: {
					layout: "AppLayoutGuest",
				},
			},
		],
	},
	{
		path: "/guest/booking/:id",
		component: GuestBooking,
		name: "guest.booking",
		props: true,
		meta: {
			layout: "AppLayoutGuest",
		},
	},
];

const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	// Check auth state through the store
	const isAuthenticated = await store.dispatch("checkAuth");

	if (to.meta.requiresAuth) {
		if (!isAuthenticated) {
			return next({ path: "/login" });
		}

		if (to.meta.adminAuth && !hasRole("superadmin")) {
			return next({ path: "/" });
		}
	} else if (to.meta.loginPages && isAuthenticated) {
		return next({ path: "/" });
	}

	return next();
});

export default router;
