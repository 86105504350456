import * as authService from "@/services/auth.service";
import router from "@/router";
import { setApiHeaders } from "@/axios";
import { isExpired } from "@/services/localStorageService";

export default {
	state: {
		isLoggedIn: false,
		// User
		user: null,
		// Tokens
		accessToken: null,
		refreshToken: null,
		// All Users
		allUsers: [],
	},

	mutations: {
		// Logged In
		setLoggedIn(state, payload) {
			state.isLoggedIn = payload;
		},

		// User
		setUser(state, payload) {
			state.user = payload;
		},

		// Token mutations
		setAccessToken(state, payload) {
			state.accessToken = payload;
		},

		setRefreshToken(state, payload) {
			state.refreshToken = payload;
		},

		// All Users
		setUsers(state, payload) {
			state.allUsers = payload;
		},
	},

	actions: {
		// Check if user is authenticated with current tokens
		async checkAuth({ dispatch }) {
			const accessToken = localStorage.getItem("user");
			const refreshToken = localStorage.getItem("refreshToken");

			// No tokens at all
			if (!accessToken) return false;

			// If access token is valid, use it
			if (!isExpired(accessToken)) {
				await dispatch("authenticateUser", accessToken);
				return true;
			}

			// Access token expired, try refresh if available
			if (refreshToken) {
				try {
					await dispatch("refreshToken");
					return true;
				} catch (error) {
					return false;
				}
			}

			// No valid authentication
			return false;
		},

		async authenticateUser({ commit, dispatch }, token) {
			try {
				setApiHeaders({ token });
				await dispatch("getUser");
				commit("setLoggedIn", true);
			} catch (err) {
				dispatch("logout");
				throw err;
			}
		},

		async login({ dispatch }, payload) {
			const { accessToken, refreshToken } = await authService.login(payload);

			// Store tokens
			localStorage.setItem("user", accessToken);
			localStorage.setItem("refreshToken", refreshToken);

			await dispatch("authenticateUser", accessToken);
			await dispatch("handlePostLoginRequests");

			router.push("/");
		},

		async refreshToken() {
			const refreshToken = localStorage.getItem("refreshToken");
			if (!refreshToken) {
				return Promise.reject(new Error("No refresh token available"));
			}

			try {
				const accessToken = await authService.refreshToken(refreshToken);

				// Update token in localStorage and API headers
				localStorage.setItem("user", accessToken);
				setApiHeaders({ token: accessToken });

				return accessToken;
			} catch (error) {
				// Clear tokens but don't trigger logout API call
				localStorage.removeItem("user");
				localStorage.removeItem("refreshToken");
				return Promise.reject(error);
			}
		},

		async logout({ commit }) {
			// Get current tokens
			const token = localStorage.getItem("user");
			const refreshToken = localStorage.getItem("refreshToken");

			// Clear local storage first to prevent loops
			localStorage.removeItem("user");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("selectedSiteID");

			// Reset state
			commit("setLoggedIn", false);
			commit("setUser", null);

			// Try API logout only if we had valid tokens
			if (token && refreshToken) {
				try {
					await authService.logout();
				} catch (error) {
					console.warn("Logout API call failed:", error);
				}
			}

			// Navigate to login
			router.push("/login");
		},

		async getUser({ commit }) {
			const user = await authService.getUser();
			commit("setUser", user);
		},

		async updateUser({ commit }, payload) {
			const user = await authService.updateUser(payload);
			commit("setUser", user);
		},

		async registerUser(_, payload) {
			await authService.registerUser(payload);
		},

		async forgotPassword(_, payload) {
			await authService.forgotPassword(payload);
		},

		async resetPassword(_, payload) {
			await authService.resetPassword(payload);
		},

		// Add a method to force logout of all existing users
		forceRelogin() {
			// We'll implement this by checking token format
			const token = localStorage.getItem("user");

			// If there's no refresh token but there is a user token,
			// they're using the old auth system
			if (token && !localStorage.getItem("refreshToken")) {
				localStorage.removeItem("user");
				localStorage.removeItem("selectedSiteID");
				router.push("/login");
				return true;
			}

			return false;
		},
	},

	getters: {
		user(state) {
			return state.user;
		},
	},
};
