<template>
	<v-card flat>
		<v-card-title class="d-flex justify-space-between align-center">
			<span class="subtitle-1 font-weight-bold">Voltages</span>
			<div>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn x-small text color="primary" v-bind="attrs" v-on="on" :disabled="loading">
							Filter
							<v-icon small>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list dense>
						<v-list-item @click="setDateRange(1)">
							<v-list-item-title>Today</v-list-item-title>
						</v-list-item>
						<v-list-item @click="setDateRange(7)">
							<v-list-item-title>Last 7 days</v-list-item-title>
						</v-list-item>
						<v-list-item @click="setDateRange(14)">
							<v-list-item-title>Last 14 days</v-list-item-title>
						</v-list-item>
						<v-list-item @click="setDateRange(30)">
							<v-list-item-title>Last 30 days</v-list-item-title>
						</v-list-item>
						<v-divider></v-divider>

						<v-menu ref="dateMenu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-list-item v-bind="attrs" v-on="on">
									<v-list-item-title>Custom dates</v-list-item-title>
								</v-list-item>
							</template>
							<v-date-picker v-model="dateRange" no-title range @input="onDateRangeSelect"></v-date-picker>
						</v-menu>
					</v-list>
				</v-menu>
				<v-btn x-small icon color="primary" @click="fetchData" :loading="loading">
					<v-icon small>mdi-refresh</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<div v-if="batteryReadings.length > 0">
			<apexchart type="area" height="250" :options="chartOptions" :series="series"></apexchart>
		</div>
		<div v-else-if="loading">
			<v-skeleton-loader type="chart"></v-skeleton-loader>
		</div>
		<div v-else>No Data</div>
	</v-card>
</template>

<script>
import { getDeviceBatteryReadings } from "@/services/devices.service";

export default {
	props: {
		deviceId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			batteryReadings: [],
			loading: false,
			queryParams: {
				limit: 100,
				page: 1,
				sort: "timestamp:asc",
			},
			dateMenu: false,
			dateRange: [],
		};
	},
	computed: {
		series() {
			return [
				{
					name: "Voltage",
					data: this.processedData.map((item) => ({
						x: item.timestamp,
						y: item.volts,
					})),
				},
			];
		},
		processedData() {
			return [...this.batteryReadings]
				.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
				.map((item) => ({
					timestamp: new Date(item.timestamp),
					volts: item.volts || 0,
				}));
		},
		chartOptions() {
			return {
				chart: {
					height: 250,
					type: "area",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					dropShadow: {
						enabled: true,
						top: 4,
						left: 0,
						blur: 2,
						opacity: 0.1,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				colors: ["#406DFA"],
				xaxis: {
					type: "datetime",
					tickAmount: 8,
					labels: {
						formatter: function (value, timestamp) {
							const date = new Date(timestamp);
							const month = date.getMonth() + 1;
							const day = date.getDate();
							const hours = date.getHours().toString().padStart(2, "0");
							const minutes = date.getMinutes().toString().padStart(2, "0");
							return `${month}/${day} ${hours}:${minutes}`;
						},
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
							cssClass: "apexcharts-xaxis-label",
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					crosshairs: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					title: {
						text: "Volts",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(1);
						},
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
							cssClass: "apexcharts-xaxis-label",
						},
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "dark",
						gradientToColors: ["#406DFA"],
						shadeIntensity: 1,
						type: "vertical",
						opacityFrom: 0.6,
						opacityTo: 0.2,
						stops: [0, 100, 100, 100],
					},
				},
				grid: {
					show: true,
				},
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex, w }) {
						const value = series[seriesIndex][dataPointIndex];
						const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];
						const date = new Date(timestamp);
						const month = date.getMonth() + 1;
						const day = date.getDate();
						const hours = date.getHours().toString().padStart(2, "0");
						const minutes = date.getMinutes().toString().padStart(2, "0");
						return `<div class="pa-2 font-weight-bold accent--text">
							<span>${month}/${day} ${hours}:${minutes}: ${value.toFixed(1)}V</span>
						</div>`;
					},
					followCursor: true,
				},
				markers: {
					size: 4,
					colors: ["#406DFA"],
					strokeWidth: 2,
					hover: {
						size: 6,
					},
					discrete: [],
				},
			};
		},
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		async fetchData() {
			if (!this.deviceId) return;

			try {
				this.loading = true;
				const data = await getDeviceBatteryReadings(this.deviceId, this.queryParams);
				this.batteryReadings = Array.isArray(data) ? data : data.data || [];
			} catch (err) {
				console.error("Error fetching battery readings:", err);
			} finally {
				this.loading = false;
			}
		},

		refreshData(params = {}) {
			this.queryParams = { ...this.queryParams, ...params };
			this.fetchData();
		},

		setDateRange(days) {
			const endDate = new Date();
			const startDate = new Date();
			startDate.setDate(startDate.getDate() - days);

			this.refreshData({
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
			});
		},

		onDateRangeSelect() {
			if (this.dateRange.length === 2) {
				const sortedDates = [...this.dateRange].sort((a, b) => new Date(a) - new Date(b));

				this.refreshData({
					startDate: sortedDates[0],
					endDate: sortedDates[1],
				});

				this.$nextTick(() => {
					this.dateMenu = false;
				});
			}
		},
	},
	watch: {
		deviceId: {
			handler() {
				this.fetchData();
			},
			immediate: true,
		},
	},
};
</script>
