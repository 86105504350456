<template>
	<div>
		<v-row class="px-4 mt-2">
			<v-col cols="12">
				<v-row>
					<v-col cols="12">
						<v-row>
							<v-col cols="12" md="3">
								<data-card color="primary lighten-4" textColor="primary" icon="mdi-golf" iconBg="primary" :value="rides.length" :text="$t('usage.totalRounds')" />
							</v-col>
							<v-col cols="12" md="3">
								<data-card color="purple lighten-4" textColor="purple" icon="mdi-clock" iconBg="purple" :value="averageRideDuration" :text="$t('usage.averageDuration')" />
							</v-col>
							<!-- <v-col>
								<data-card color="green lighten-4" textColor="green" icon="mdi-calendar-clock" iconBg="green" :value="outlierCount" :text="$t('usage.outliers')" />
							</v-col> -->
							<!-- <v-col cols="12" md="6">
								<PeakTimesDisplay :rides="rides" :ride-type="rideType" :selected-cart="selectedCart" />
							</v-col> -->
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-card flat class="rounded-lg translucentBackground">
							<v-card-title class="title font-weight-bold accent--text">
								<v-icon small color="accent" left> mdi-chart-line </v-icon>
								{{ $t("usage.dailyUsage") }}
							</v-card-title>
							<v-card-text>
								<usage-chart :rides="rides" />
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" md="8">
						<v-card flat class="rounded-lg translucentBackground">
							<v-card-title class="title font-weight-bold accent--text">
								<v-icon small color="accent" left> mdi-golf-cart </v-icon>

								{{ $t("usage.usageByCart") }}
							</v-card-title>
							<v-card-text>
								<usage-by-cart-chart :rides="rides" />
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" md="4">
						<v-card :disabled="!services.length" flat class="rounded-lg translucentBackground">
							<v-card-title class="title font-weight-bold accent--text">
								<span>
									<v-icon small color="accent" left> mdi-golf</v-icon>
									{{ showByPrice ? $t("usage.ridesByPrice") : $t("usage.ridesByService") }}
								</span>
								<v-spacer></v-spacer>

								<v-select
									v-model="showByPrice"
									item-text="name"
									item-value="val"
									style="max-width: 130px; scale: 0.8; position: absolute; right: 0; top: 10px; background-color: #fff"
									hide-details
									outlined
									dense
									:items="[
										{ name: $t('services.service'), val: false },
										{ name: $t('services.price'), val: true },
									]"
								/>
							</v-card-title>
							<v-card-text v-if="services.length">
								<rides-by-price-chart v-if="showByPrice" :rides="rides" :services="services" />
								<rides-by-service-chart v-else :rides="rides" :services="services" />
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-card flat class="rounded-lg translucentBackground">
							<v-card-title class="title font-weight-bold accent--text">
								<v-icon small color="accent" left>{{ heatmapRideType === "booking" ? "mdi-calendar-month" : "mdi-fire" }}</v-icon>
								<span>
									{{ heatmapRideType === "booking" ? $t("usage.bookingsHeatmap") : $t("usage.roundsHeatmap") }} -
									{{ selectedCartHeatmap ? `Cart: ${selectedCartHeatmap}` : $t("general.allCarts") }}
								</span>
								<v-spacer></v-spacer>
								<v-select
									v-model="selectedCartHeatmap"
									:label="$t('general.selectCart')"
									item-text="carNumber"
									item-value="carNumber"
									clearable
									style="max-width: 130px; scale: 0.8; position: absolute; right: 120px; top: 10px; background-color: #fff"
									hide-details
									outlined
									dense
									:items="devices"
								/>

								<v-select
									v-model="heatmapRideType"
									label="Origin"
									item-text="name"
									item-value="val"
									style="max-width: 130px; scale: 0.8; position: absolute; right: 0; top: 10px; background-color: #fff"
									hide-details
									outlined
									dense
									:items="[
										{ name: 'All', val: null },
										{ name: $t('bookings.booking'), val: 'booking' },
										{ name: $t('general.dashboard'), val: 'manual' },
									]"
								/>
							</v-card-title>
							<v-card-text>
								<heatmap-chart :rides="rides" :ride-type="heatmapRideType" :selected-cart="selectedCartHeatmap" />
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { differenceInSeconds } from "date-fns";
import DataCard from "@/components/charts/DataCard";
import UsageChart from "@/components/charts/UsageChart";
import RidesByServiceChart from "@/components/charts/RidesByServiceChart";
import RidesByPriceChart from "../../components/charts/RidesByPriceChart.vue";
import UsageByCartChart from "@/components/charts/UsageByCartChart";
import HeatmapChart from "@/components/charts/HeatmapChart";
// import PeakTimesDisplay from "@/components/charts/PeakTimesDisplay";

export default {
	components: {
		DataCard,
		UsageChart,
		RidesByServiceChart,
		UsageByCartChart,
		HeatmapChart,
		RidesByPriceChart,
		// PeakTimesDisplay,
	},
	props: {
		dateRange: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selectedService: null,
			rides: [],
			rentalGoal: 2,
			showByPrice: false,
			heatmapRideType: null,
			selectedCartHeatmap: null,
		};
	},
	watch: {
		dateRange: {
			handler(val) {
				if (val.start && val.end) {
					this.fetchRideData();
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapState({
			services: (state) => state.sites.services,
			devices: (state) => state.devices.devices,
		}),

		service: {
			get() {
				return this.selectedService ? this.selectedService : this.services[0]?._id || null;
			},
			set(val) {
				this.selectedService = val;
			},
		},

		averageRideDuration() {
			if (this.rides.length === 0) {
				return "0 seconds";
			}

			// Get all durations with their metadata
			const durationsWithMetadata = this.rides.map((ride) => ({
				id: ride._id,
				duration: ride.duration,
				carNumber: ride.carNumber,
				start: ride.start,
				end: ride.end,
			}));

			// Sort durations for quartile calculations
			const sorted = [...durationsWithMetadata].sort((a, b) => a.duration - b.duration);

			// Calculate quartile positions more precisely
			const q1Pos = Math.floor((sorted.length - 1) * 0.25);
			const q3Pos = Math.floor((sorted.length - 1) * 0.75);

			// Get quartile values
			const q1 = sorted[q1Pos].duration;
			const q3 = sorted[q3Pos].duration;

			// Calculate IQR and bounds
			const iqr = q3 - q1;
			const lowerBound = q1 - iqr * 1.5;
			const upperBound = q3 + iqr * 1.5;

			// Find outliers
			const outliers = durationsWithMetadata.filter((item) => item.duration < lowerBound || item.duration > upperBound);

			// Log statistical information and outliers
			console.group("Ride Duration Analysis");
			console.log("Statistical Measures:", {
				q1: `${Math.floor(q1 / 60)} minutes`,
				median: `${Math.floor(sorted[Math.floor(sorted.length / 2)].duration / 60)} minutes`,
				q3: `${Math.floor(q3 / 60)} minutes`,
				iqr: `${Math.floor(iqr / 60)} minutes`,
				lowerBound: `${Math.floor(lowerBound / 60)} minutes`,
				upperBound: `${Math.floor(upperBound / 60)} minutes`,
			});

			if (outliers.length > 0) {
				console.log(`Found ${outliers.length} outliers:`);
				outliers.forEach((outlier) => {
					console.log({
						id: outlier.id,
						carNumber: outlier.carNumber,
						duration: `${Math.floor(outlier.duration / 60)} minutes (${Math.floor(outlier.duration / 3600)} hours)`,
						start: new Date(outlier.start).toLocaleString(),
						end: new Date(outlier.end).toLocaleString(),
					});
				});
			}
			console.groupEnd();

			// Filter out outliers for average calculation
			const filteredDurations = durationsWithMetadata.filter((item) => item.duration >= lowerBound && item.duration <= upperBound).map((item) => item.duration);

			// Calculate average of filtered values
			const totalDuration = filteredDurations.reduce((acc, duration) => acc + duration, 0);
			const average = totalDuration / filteredDurations.length;

			// Convert average duration to hours, minutes, seconds
			const hours = Math.floor(average / 3600);
			const minutes = Math.floor((average % 3600) / 60);
			const seconds = Math.floor(average % 60);

			// Build result string
			let result = [];
			if (hours > 0) {
				result.push(`${hours}hrs`);
			}
			if (minutes > 0) {
				result.push(`${minutes}mins`);
			}
			if (seconds > 0 || result.length === 0) {
				result.push(`${seconds}secs`);
			}

			return result.join(", ");
		},

		outlierCount() {
			if (this.rides.length === 0) return 0;

			const durations = this.rides.map((ride) => ride.duration);
			const sorted = [...durations].sort((a, b) => a - b);

			const q1 = sorted[Math.floor(sorted.length * 0.25)];
			const q3 = sorted[Math.floor(sorted.length * 0.75)];
			const iqr = q3 - q1;
			const lowerBound = q1 - iqr * 1.5;
			const upperBound = q3 + iqr * 1.5;

			return durations.filter((d) => d < lowerBound || d > upperBound).length;
		},
	},
	mounted() {
		this.fetchRideData();
	},
	methods: {
		prepareQuery() {
			const queryParams = {
				...this.dateRange,
				fields: ["device", "type", "carNumber", "meta", "start", "end", "service", "price", "booking", "duration"],
			};

			return queryParams;
		},

		async fetchRideData() {
			const rides = await this.$store.dispatch("rides/getRidesChartData", this.prepareQuery());

			rides.forEach((ride) => {
				if (!ride.duration) {
					ride.duration = this.calculateDuration(ride.start, ride.end);
				}
			});

			this.rides = rides;
		},

		calculateDuration(start, end) {
			const duration = differenceInSeconds(new Date(end), new Date(start));
			// If the duration is greater than 10 hours, return 0
			return duration > 36000 ? 0 : duration;
		},
	},
};
</script>

<style scoped>
.translucentBackground {
	background-color: rgb(199, 214, 255, 0.3);
}
</style>
